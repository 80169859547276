import {
    USER_BALANCE
} from "../actions/actionTypes/balance.action-types";

const initialState = {
    userBalance: 0,
};

export default function balance(state = initialState, action) {
    switch (action.type) {
        case USER_BALANCE: {
            const userBalance  = action.payload;

            return {
                ...state,
                userBalance,
            };
        }

        default:
            return state;
    }
};
