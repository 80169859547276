import { combineReducers } from 'redux';

import pools from "./pool.reducer";
import user from "./userInfo.reducer";
import balance from "./balance.reducer";

const reducers = combineReducers({
    pools,
    user,
    balance,
});

export { reducers };
